<template>
  <Modal v-if="modal" class="custom-modal" @close="close">
    <template #header>
      <div class="Notification__modalHeader">
        {{ type === 'edit' ? 'Editar' : 'Novo' }} disparo de push
      </div>
    </template>
    <template #body>
      <div class="row">
        <div class="col">
          <DynamicForm
            ref="form"
            class="Notification__form"
            :model="form"
            :blueprint="
              blueprint({todayParsed, type, disabledFields, rulesFields})
            "
            :endpoint-errors="endpointErrors"
            submit-type="json"
            @submit="handlerSubmit"
          />
        </div>
        <div class="col">
          <div class="device">
            <div class="case">
              <div class="sound" />
              <div class="screen">
                <div class="messenger">
                  <div class="messenger__grp">
                    <span class="messenger__title">{{
                      form.title.length > 0
                        ? previewTitleMessage
                        : 'Titulo da mensagem'
                    }}</span>
                    <span class="messenger__text">{{
                      form.message.length > 0
                        ? form.message
                        : 'Caso a mensagem seja muito extensa, o usuário poderá visualizar toda mensagem no app.'
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import {mapActions} from 'vuex'
import {DynamicForm, Modal} from '@/components/molecules'
import blueprint from './NotificationPushForm.blueprint'

export default {
  name: 'NotificationPushForm',
  components: {
    Modal,
    DynamicForm,
  },
  props: {
    type: {
      type: [String],
      description: 'Type of content',
      default: 'new',
    },
  },
  data() {
    return {
      //Component
      modal: false,
      pushId: null,

      //Form
      form: {
        date_schedule: '',
        name_schedule: '',
        store: [],
        user: [],
        title: '',
        message: '',
        link: null,
        confirmButton: null,
        cancelButton: null,
      },
      endpointErrors: {},
    }
  },
  computed: {
    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },
    disabledFields() {
      return {
        store: this.form.user.length > 0,
        user: this.form.store.length > 0,
        link: this.form.link && this.form.link.length > 0,
        confirmButton: this.form.link && this.form.link.length === 0,
        cancelButton: this.form.link && this.form.link.length === 0,
      }
    },
    rulesFields() {
      return {
        link: this.form.link && this.form.link.length > 0 ? 'regex' : '',
        confirmButton: this.form.link && this.form.link.length > 0 ? 'required' : '',
        cancelButton: this.form.link && this.form.link.length > 0 ? 'required' : '',
      }
    },
    previewTitleMessage() {
      if (this.form.title.length > 36) {
        return this.form.title.substr(0, 36) + '...'
      }
      return this.form.title
    },
  },
  watch: {
    modal: function(val) {
      if (val) {
        this.handlerPushModal()
      }
    },
  },
  methods: {
    blueprint,

    ...mapActions({
      setLoading: 'loading/setLoading',
      getPush: 'notification/getPush',
      createPush: 'notification/createPush',
      updatePush: 'notification/updatePush',
    }),

    //Handlers
    async handlerPushModal() {
      this.clearForm()

      if (this.type !== 'new') {
        await this.getNotificationPush()
      }
    },

    async handlerSubmit(formData) {
      this.parsedLink()
      let data = {
        ...formData,
        store: formData.store.map((el) => el.id),
        user: formData.user.map((el) => el.id),
      }
      if (this.pushId === null || this.type === 'resend') {
        await this.createPush(data)
      } else {
        data.id = this.pushId
        await this.updatePush(data)
      }
      this.reset()
      this.$emit('submit')
    },

    //Actions
    async getNotificationPush() {
      const response = await this.getPush({id: this.pushId})
      this.populatePushForm(response)
    },

    //Helpers
    parsedLink() {
      if (this.form.link) {
        var re = new RegExp('^(http|https)://', 'i')
        var match = re.test(this.form.link)

        if (!match) {
          this.form.link = '//' + this.form.link
        }
      }
    },

    populatePushForm(response) {
      //Check link
      if (response.link !== null) {
        response.link = response.link.indexOf('//') === 0 ? response.link.slice(2) : response.link
      }

      this.form.name_schedule = response.name_schedule
      this.form.title = response.title
      this.form.message = response.message
      this.form.store = response.stores
      this.form.user = response.users
      this.form.link = response.link
      this.form.confirmButton = response.confirmButton
      this.form.cancelButton = response.cancelButton
      if (this.type === 'edit') {
        this.form.date_schedule = response.date_schedule
      }
    },

    clearForm() {
      this.form = {
        date_schedule: '',
        name_schedule: '',
        store: [],
        user: [],
        title: '',
        message: '',
        link: '',
        confirmButton: '',
        cancelButton: '',
      }
    },

    //Component
    open(data) {
      this.pushId = data !== undefined ? data.id : null
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    reset() {
      this.clearForm()
      this.pushId = null
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped></style>

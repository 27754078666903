export default ({todayParsed, type, disabledFields, rulesFields}) => {

  const linkForm = [
    {
      id: 'confirmButton',
      model: 'confirmButton',
      tag: 'InputGroup',
      rules: [rulesFields.confirmButton],
      props: {
        label: 'Botão de Confirmação',
        placeholder: 'Digite o texto para botão',
        invalidLabel: 'Insira um texto para o botão de confirmação',
        charactersLimit: 50,
        disabled: disabledFields.confirmButton
      }
    },
    {
      id: 'cancelButton',
      model: 'cancelButton',
      tag: 'InputGroup',
      rules: [rulesFields.cancelButton],
      props: {
        label: 'Botão de Cancelamento',
        placeholder: 'Digite o texto para botão',
        invalidLabel: 'Insira um texto para o botão de cancelamento',
        charactersLimit: 50,
        disabled: disabledFields.cancelButton
      }
    },
  ]

  return [
    {
      id: 'date_schedule',
      model: 'date_schedule',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Data do disparo',
        placeholder: 'Data a ser efetuado o disparo',
        notBefore: todayParsed,
        type: 'datetime',
        range: false,
        prependIcon: false,
      }
    },
    {
      id: 'name_schedule',
      model: 'name_schedule',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome do disparo',
        placeholder: 'Digite um nome para o disparo',
        invalidLabel: 'Insira um nome do disparo',
      }
    },
    {
      id: 'store',
      model: 'store',
      tag: 'BaseTreeSelect',
      props: {
        name: 'Lojas',
        label: 'Lojas',
        placeholder: 'Todas as Lojas',
        multiple: true,
        tags: true,
        preserveHeight: true,
        selectAll: false,
        selectOptions: null,
        fetchOptions: 'stores/fetchStoresAsync',
        readOnly: disabledFields.store
      }
    },
    {
      id: 'user',
      model: 'user',
      tag: 'BaseTreeSelect',
      props: {
        name: 'Motoristas',
        label: 'Motoristas',
        placeholder: 'Todos os Motoristas',
        multiple: true,
        tags: true,
        preserveHeight: true,
        selectAll: false,
        suggestions: 'searchDrivers',
        readOnly: disabledFields.user
      }
    }, 
    {
      id: 'title',
      model: 'title',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Título do disparo',
        placeholder: 'Digite o título do disparo',
        invalidLabel: 'Insira um título para disparo',
        charactersLimit: 65,
      }
    },  
    {
      id: 'message',
      model: 'message',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Texto do disparo',
        placeholder: 'Digite o texto da mensagem',
        invalidLabel: 'Insira um texto para disparo',
        charactersLimit: 240,
        textArea: true
      }
    },
    {
      id: 'link',
      model: 'link',
      tag: 'InputGroup',
      rules: [rulesFields.link],
      regex: '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
      props: {
        label: 'Link',
        placeholder: 'Digite a url',
        invalidLabel: 'Insira uma url valida',
        charactersLimit: 250,
     
      }
    },
    ...(disabledFields.link ? linkForm : []),
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: `${type === 'edit' ? 'Editar' : 'Agendar'} Envio`,
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true,
      }
    },
  ]
}
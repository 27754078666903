<template>
  <main class="Notification">
    <TheSubHeader
      icon="notify"
      title="Comunicações"
      page-title-tag="Notification"
      type="tabs"
      organizer-gap="1rem"
      organizer-columns="1fr"
      :tabs="tabs"
      @changeTab="changeCurrentTab($event)"
    >
      <template #afterButtons>
        <BaseButton
          v-if="disableActions"
          icon="add"
          color="primary-base"
          label="Cadastrar novo push"
          class="TheSubHeader__button"
          :track="trackSubheaderButtonString('Notification', 'Addnew')"
          @click="handlerNew()"
        />
      </template>
      <template #filter>
        <section
          v-if="currentView === 'NotificationPush'"
          class="Notification__subheaderTab Notification__subheaderTab--push"
        >
          <!-- Period -->
          <BaseDatePicker 
            v-model="formattedDateRange" 
            @input="resetSearch()" 
          />
          <!-- Push Name -->
          <InputGroup 
            v-model="filters.push_name" 
            placeholder="Pesquisar" 
            label="Nome do disparo" 
            @debounce="resetSearch()" 
          />
          <!-- Stores -->
          <BaseTreeSelect
            ref="lojista"
            v-model="filters.store_id"
            name="Lojista"
            label="Lojista"
            placeholder="Selecione"
            multiple
            :select-options="null"
            :fetch-options="'stores/fetchStoresImportAsync'"
            @input="resetSearch()"
          />
          <!-- Driver -->
          <BaseTreeSelect
            v-model="filters.driver_id"
            name="Motorista"
            label="Motorista"
            placeholder="Selecione"
            multiple
            suggestions="searchDrivers"
            @input="resetSearch()"
          />
          <!-- Status -->
          <BaseTreeSelect
            v-model="filters.status"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :select-options="statusList"
            @input="resetSearch()"
          />
        </section>
      </template>
      <template #tag>
        <FiltersTag
          v-if="allTags.length > 0 && currentView === 'NotificationPush'"
          :tags="allTags"
          @removeTag="removeTag"
          @clear="clearAllTags"
        />
      </template>
    </TheSubHeader>

    <!--Content -->
    <section class="Notification__Content">
      <component
        :is="currentView"
        ref="component"
        @hook:mounted="childMounted"
        @reset="resetComponent"
        @update="updateComponent"
        @submit="handlerSubmit"
      />
    </section>
  </main>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {TheSubHeader} from '@/components/organisms'
import {
  BaseButton,
  BaseDatePicker,
  BaseTreeSelect,
} from '@/components/atoms'
import {FiltersTag, InputGroup} from '@/components/molecules'

//Views
import NotificationPush from './Push/NotificationPush.vue'

export default {
  name: 'Notification',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    FiltersTag,
    InputGroup,
    NotificationPush,
  },
  data() {
    return {
      //Component
      currentView: 'NotificationPush',
      tabs: [
        {
          id: 0,
          component: 'NotificationPush',
          title: 'Notificações Push',
        },
      ],

      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        push_name: '',
        store_id: [],
        driver_id: [],
        status: null,
      },
      allTags: [],

      //Helpers
      statusList: [
        {id: 3, name: 'Pendente'},
        {id: 5, name: 'Concluído'},
      ],
    }
  },

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),

    disableActions() {
      return (
        this.permissions &&
        this.permissions.notification &&
        this.permissions.notification.addNewButton &&
        this.currentView === 'NotificationPush'
      )
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },
  },
  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
      setParameters: 'queryString/setParameters',
    }),

    //Component
    init() {
      this.$refs.component.init(this.filters)
    },

    changeCurrentTab(e) {
      this.currentView = e.component
    },

    //Filters
    search() {
      this.$nextTick(async () => {
        this.$refs.component.search(this.filters)
      })
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    updateTags() {
      this.allTags = [
        ...this.filters.store_id,
        ...this.filters.driver_id,
      ]
    },

    removeTag(name, id) {
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.store_id = this.filters.store_id.filter(
        (tag) => tag.name !== name
      )
      this.filters.driver_id = this.filters.driver_id.filter(
        (tag) => tag.name !== name
      )

      this.resetSearch()
    },

    clearAllTags() {
      this.allTags = []
      this.filters.push_name = ''
      this.filters.store_id = []
      this.filters.driver_id = []

      this.resetSearch()
    },

    //Handlers
    handlerNew() {
      this.$refs.component.handlerNew()
    },

    handlerSubmit() {
      this.$refs.component.search(this.filters)
    },

    //Emits
    updateComponent() {
      this.updateTags()
    },

    resetComponent() {
      this.resetSearch()
    },

    childMounted() {
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .Notification {
  &__subheaderTab {
    display: grid;
    grid-column-gap: 1rem;
    align-items: end;
    width: 100%;

    &--push {
      grid-template-columns: 240px 1fr 1fr 1fr 1fr;

      @media #{$mobile-view} {
        grid-template-columns: 1fr;
        gap: 1rem;
        grid-template-rows: 1fr;
      }
    }
  }

  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
  &__Dialog{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-modal-bg;
    &--hide{
      display: none;
    }
  }

  &__tab {
    width: 100%;
  }


  &__modalHeader {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }
  &__form {
    max-width: 100%;
    width: 100%;
    margin: 0px auto;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}

/deep/ .row {
  display: flex;
  justify-content: space-around;
  .col {
    width: 45%;
    @media (max-width: 800px) {
      width: 95%;
      &:last-child {
        display: none;
      }
    }
  }
}
/deep/ .device {
  width: 100%;
  padding: 100px 0;
  .case {
    margin: 0 auto;
    width: 375px;
    border-radius: 35% 35% 0 0;
    background-color: $color-neutral-stronger;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #ccc;
    border-bottom: 0px;
    .sound {
      width: 100px;
      height: 7px;
      background: #f3f3f3;
      border-radius: 5px;
      margin-bottom: 35px;
    }
    .screen {
      background: #ccc;
      border: none;
      width: 90%;
      height: 115px;
      margin: 0 auto;
      .img {
        &__icon {
          width: 20px;
          height: 20px;
        }
      }
      .messenger {
        display: flex;
        justify-content: space-between;
        line-height: $line-height-2xsmall;
        padding: 10px;
        margin: 5px;
        background-color: $color-neutral-softest;
        // height: 85%;
        &__title {
          font-weight: $font-weight-bold;
          font-size: $font-size-1xmini;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        &__text {
          margin-top: 3px;
          color: #3e3e3e;
          font-size: $font-size-1xmini;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        &__img {
          padding: 0 10px;
          margin: 0px 10px;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          align-self: center;
        }
        &__grp {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.custom-modal {
  .Modal__container {
    width: calc(100% - 30px) !important;
    max-width: 960px;
    @media (max-width: 800px) {
      width: 90% !important;
    }
  }
  .device {
    @media #{$mobile-view} {
      display: none;
    }
  }
}
.Notification {
  .Modal__container {
    height: auto;
    max-height: 90%;
  }
  .Modal__containerBody {
    height: auto;
  }
}
.Notification__form {
  .InputGroup {
    width: 100%;
    &__textarea {
      height: 100px;
    }
  }
  @media #{$mobile-view} {
    .BaseButton {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .DynamicForm__preview {
    @media #{$mobile-view} {
      display: none;
    }
  }
}
</style>

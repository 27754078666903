<template>
  <Fragment>
    <NotificationPushForm ref="NotificationPushForm" :type="formType" @submit="handlerSubmit" />
    <NotificationPushTable ref="NotificationTable" @edit="handlerEdit" @resend="handlerResend" />
  </Fragment>
</template>
<script>
import {Fragment} from 'vue-fragment'
import NotificationPushTable from './NotificationPushTable'
import NotificationPushForm from './Form/NotificationPushForm'

export default {
  name: 'NotificationPush',
  components: {
    Fragment,
    NotificationPushTable,
    NotificationPushForm,
  },
  data() {
    return {
      formType: 'new',
    }
  },
  methods: {
    //Init
    init(params) {
      this.search(params)
    },

    search(values) {
      this.$refs.NotificationTable.search(values)
      this.$emit('update')
    },

    //Handlers
    handlerNew() {
      this.formType = 'new'
      this.$refs.NotificationPushForm.open()
    },

    handlerEdit(data) {
      this.formType = 'edit'
      this.$refs.NotificationPushForm.open(data)
    },

    handlerResend(data) {
      this.formType = 'resend'
      this.$refs.NotificationPushForm.open(data)
    },

    handlerSubmit() {
      this.formType = 'new'
      this.$emit('submit')
    },

  },
}
</script>

<template>
  <Fragment>
    <div class="Notification__Table">
      <DataTable
        :header="pushHistory.data.headers"
        :table="pushHistory.data.content"
        @edit="editPushModal"
        @resend="resendPushModal"
        @remove="openDeletePush"
      />
      <Pagination @page="search()" @paginate="search()" />
    </div>
    <Dialog
      v-if="showDeletePush"
      warning="Tem certeza que deseja remover e cancelar o envio desse push?"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="confirmDeletePush"
      @cancel="cancelDeletePush"
    />
  </Fragment>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {Fragment} from 'vue-fragment'
import {DataTable, Pagination, Dialog} from '@/components/molecules'

export default {
  name: 'NotificationPushTable',
  components: {
    Fragment,
    DataTable,
    Pagination,
    Dialog,
  },
  data() {
    return {
      filters: {},

      pushIdToAction: null,
      showDeletePush: false,
    }
  },
  computed: {
    ...mapState({
      page: (state) => state.pagination.page,
      paginate: (state) => state.pagination.paginate,
      pushHistory: (state) => state.notification.pushHistory,
    }),
  },
  methods: {
    ...mapActions({
      setLengthItems: 'pagination/setLengthItems',
      fetchPushHistory: 'notification/fetchPushHistory',
      deletePush: 'notification/deletePush',
      setPagination: 'pagination/setPagination',
    }),

    //Init
    init(params) {
      this.search(params)
    },

    //Actions
    async search(values) {
      //Check if click is on filters or paginate buttons
      if (values !== undefined) {
        this.filters = values
      }
      const params = {
        obj: {
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          'name_schedule[value]': this.filters.push_name,
          'filter[stores][store_id]': this.$parent.$parent.$refs.lojista.checkSelectAll ? [] : this.filters.store_id,
          'filter[users][user_id]': this.filters.driver_id,
          'filter[status][id]': this.filters.status,
        },
        page: this.page,
        paginate: this.paginate,
      }

      await this.fetchPushHistory(params).then(() => {
        this.setPagination(this.pushHistory)
      })

      this.$emit('update')
    },

    //Handlers
    editPushModal(data) {
      this.$emit('edit', data)
      this.pushIdToAction = data.id
    },

    resendPushModal(data) {
      this.$emit('resend', data)
      this.pushIdToAction = data.id
    },

    openDeletePush(row) {
      this.pushIdToAction = row.id
      this.showDeletePush = true
    },

    confirmDeletePush() {
      this.deletePush({id: this.pushIdToAction}).then(() => {
        this.pushIdToAction = null
        this.showDeletePush = false
      })
    },

    cancelDeletePush() {
      this.showDeletePush = false
      this.pushIdToAction = null
    },
  },
}
</script>